// Score Board

.score-board {
  width: px(1061);
  height: px(177);
  position: relative;

  &:before {
    content: '';
    display: block;
    width: px(1092);
    height: px(236);
    user-select: none;
    pointer-events: none;
    position: absolute;
    top: px(-30);
    left: 0;
    background: url('#{$cdn}/img/bg-score.png') 0 0/100% 100% no-repeat;
  }

  &__left, &__right {
    position: absolute;
    top: 0;
    font-size: px(81.12);
    color: $white;
    height: 100%;
    width: px(93);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    &.small {
      font-size: px(60);
    }
  }

  &__left {
    left: px(356);
  }

  &__right {
    right: px(356);
  }
}
