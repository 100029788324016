// Rules

.modal-content {
  .rules {
    &__content {
      font-size: px(30);
      line-height: 1.4em;

      &>* {
        &:first-child {
          margin-top: 0 !important;
        }

        &:last-child {
          margin-bottom: 0 !important;
        }
      }

      p {
        margin-bottom: px(15);
      }
    }
  }
}
