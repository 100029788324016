// Coupon

.coupon {
  $self: '.coupon';

  width: px(192);
  height: px(107);
  position: relative;
  text-align: center;

  &__turn {
    font-size: px(31.49);
  }

  &__price {
    font-size: px(52.49);
    line-height: 1em;
    padding-top: px(3);

    img {
      height: px(23);
      margin: px(18) px(5) 0 0;
    }
  }

  &[data-side="left"] {

  }

  &[data-side="right"] {
    #{$self}__turn {
      color: $colorBlue2 !important;
    }
  }
}
