// Animations

@keyframes twinkle {
  0%,
  100% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, px(-20));
  }
}
