// Modal

.ReactModal__Overlay {
  display: flex !important;
  position: fixed !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0;
  left: 0 !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 !important;
  z-index: 1060 !important;
  overflow: hidden !important;
  -webkit-overflow-scrolling: touch !important;
  background: rgba($black, .85) !important;
}

.ReactModal__Content {
  display: flex;
  position: relative;
  flex-direction: column;
  font-family: inherit;
  box-sizing: border-box;
  margin: auto;
  border-radius: 0 !important;
  background: transparent;
  border: 0 !important;
  padding: 0 !important;
  outline: none;
  opacity: 0;
  transform: translateY(-25%);
  transition: all 500ms ease;

  &--after-open {
    opacity: 1;
    transform: translateY(0);
  }

  &--before-close {
    opacity: 0;
    transform: translateY(-25%);
  }

  .modal-wrapper {
    width: 100%;
    height: 100%;
    position: relative;

    .modal-body {
      overflow: hidden;
      will-change: transform;
      height: 100%;

      .modal-content {
        width: 100%;
      }
    }
  }

  .close {
    position: absolute;
    opacity: 1 !important;
    outline: none !important;
    z-index: 9;
    cursor: pointer;
    display: inline-block;
    line-height: 1em;

    img {
      width: px(49);
    }
  }

  // DEFAULT
  &.modal-theme-default {
    width: px(1488) !important;
    height: px(885) !important;

    .close {
      top: px(2);
      right: px(-66);
    }

    .modal-wrapper {
      .modal-body {
        padding: px(200) px(70) px(52);
        background: url('#{$cdn}/img/bg-popup.png') 0 0/100% 100% no-repeat;

        .ScrollbarsCustom {
          height: px(565) !important;
          margin-top: px(35);

          .ScrollbarsCustom-Track {
            right: px(-30) !important;
          }
        }

        .modal-title {
          width: px(950);
          height: px(158);
          font-size: px(95);
          font-family: $font-family-cintra;
          text-align: center;
          text-transform: uppercase;
          margin: 0;
          padding: px(10) 0 0;
          position: absolute;
          top: 0;
          left: calc(50% - #{px(950/2)});
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white !important;
          white-space: nowrap;
        }
      }
    }
  }

  // WELCOME
  &.modal-theme-welcome {
    width: px(1488) !important;
    height: px(983) !important;

    .close {
      top: px(100);
      right: px(-66);
    }

    .modal-wrapper {
      .modal-body {
        .modal-content {
          img {
            width: 100%;
          }
        }
      }
    }
  }
}
