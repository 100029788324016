// Header

.header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: px(53) px(86) 0 px(71);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 200;

  &__left {
    a {
      height: px(60);
      position: relative;
      display: inline-block;

      &:nth-child(1) {
        width: px(144);
      }

      &:nth-child(2) {
        width: px(250);
      }

      & ~ a {
        margin-left: px(10);
      }

      img {
        height: px(128);
        position: absolute;
        top: 50%;
        left: 50%;
        user-select: none;
        pointer-events: none;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__right {
    text-align: center;
  }

  &__user {
    width: px(384);
    height: px(63);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
      content: '';
      width: px(433);
      height: px(117);
      user-select: none;
      pointer-events: none;
      position: absolute;
      top: calc(50% - #{px(117/2)});
      left: calc(50% - #{px(433/2)});
      background: url('#{$cdn}/img/bg-coupon.png') 0 0/100% 100% no-repeat;
    }

    em, strong {
      position: relative;
      z-index: 2;
      font-style: italic;
    }

    em {
      font-size: px(25);
      font-weight: 300;
      margin-top: px(13);
      font-family: $font-family-roboto;
    }

    strong {
      font-size: px(35);
      display: inline-block;
      max-width: px(220);
      font-family: $font-family-bebas;
      padding-right: px(10);
    }
  }

  &__coupon {
    line-height: 1.2em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: px(17);
    font-style: italic;

    em {
      font-size: px(18.73);
      font-family: $font-family-roboto;
      @include gradientX(#d84ce7, #24dae3);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    img {
      height: px(26);
      margin-left: px(7);
    }

    span {
      font-size: px(38.61);
      font-family: $font-family-cintra;
    }
  }
}
