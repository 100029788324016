// Home

.page-home {
  &__logo {
    position: absolute;
    top: px(105);
    left: calc(50% - #{px(950 / 2)});
    width: px(950);
    z-index: 200;
    user-select: none;
    pointer-events: none;

    img {
      width: 100%;
    }
  }

  &__balance {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
