// Gift Item

.gift-item {
  $self: '.gift-item';

  display: block;
  cursor: pointer;
  transition: transform 300ms ease;

  &.selected {
    transform: scale(1.15);

    #{$self}__info {
      &:before {
        content: '';
        width: px(35);
        height: px(35);
        display: block;
        background: $colorCyan url('#{$cdn}/img/icon-tick.png') center center/80% 80% no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 10;
        box-shadow: 0 0 px(10) $black;
      }
    }
  }

  &__info {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    &--image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &--name {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 1.2em;
      text-align: center;
    }

    &--description {
      position: absolute;
      font-weight: 700;
      color: yellow;
      text-shadow: px(1) px(1) px(4) $black;
      line-height: 1em;
      font-family: $font-family-roboto;
    }

    &:hover {
      #{$self}__info--name {
        color: $white;
        background: rgba($black, .7);
      }
    }
  }

  &[data-theme="default"] {
    width: px(163);
    height: px(163);
    padding: px(10);
    background: url('#{$cdn}/img/bg-gift-item.png') 0 0/100% 100% no-repeat;

    #{$self}__info {
      &--name {
        font-size: px(20);
        padding: px(5);
      }

      &--description {
        bottom: px(5);
        right: px(5);
        font-size: px(24);
      }
    }

    &.active {
      opacity: .4;
    }
  }

  &[data-theme="left"], &[data-theme="right"] {
    width: px(84);
    height: px(88);
    padding: px(2.5);

    #{$self}__info {
      width: 100%;
      height: 100%;

      &--name {
        display: none;
      }

      &--description {
        bottom: px(2);
        right: px(2);
        font-size: px(16);
      }
    }

    &.active {
      filter: grayscale(.4) brightness(.6);
    }
  }

  &[data-theme="left"] {
    box-shadow: 0 0 px(7) $colorCyan3;
    filter: drop-shadow(0 0 px(7) $colorCyan3);
    border: px(2.5) solid $colorCyan2;
  }

  &[data-theme="right"] {
    box-shadow: 0 0 px(7) $colorPink3;
    filter: drop-shadow(0 0 px(7) $colorPink3);
    border: px(2.5) solid $colorPink2;
  }
}
