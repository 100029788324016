// Select jackpot

.select-jackpot {
  $self: '.select-jackpot';

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: px(20);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: px(69);
    left: 50%;
    width: px(185);
    height: px(455);
    display: block;
    user-select: none;
    pointer-events: none;
    transform: translateX(-50%);
    background: url('#{$cdn}/img/decor-line-vertical.png') 0 0/100% 100% no-repeat;
  }

  &__left, &__right {
    width: px(600);

    .ScrollbarsCustom {
      max-height: px(400) !important;
      margin-top: 0 !important;

      .ScrollbarsCustom-Track {
        right: px(-30) !important;
      }
    }
  }

  &__title {
    text-align: center;
    display: block;
    margin: 0 auto px(27);
    position: relative;

    img {
      position: absolute;
      user-select: none;
      pointer-events: none;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 px(-16);
    overflow: hidden;

    .gift-item {
      margin: px(14) px(16);
    }
  }

  &__submit {
    width: 100%;
    text-align: center;
    margin-top: px(24);

    a {
      width: px(317);
      height: px(78);
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $white;
      font-style: normal;
      font-size: px(55);
      position: relative;
      padding-right: px(15);

      span {
        position: relative;
        z-index: 1;
      }

      &:before {
        content: '';
        width: px(322);
        height: px(97);
        display: block;
        user-select: none;
        pointer-events: none;
        position: absolute;
        top: px(-7);
        left: px(-4);
        background: url('#{$cdn}/img/btn-pink-cyan.png') 0 0/100% 100% no-repeat;
      }
    }
  }

  &__left {
    #{$self}__title {
      width: px(284);
      height: px(64);

      img {
        top: px(-18);
        left: px(-19);
        height: px(101);
      }
    }
  }

  &__right {
    #{$self}__title {
      width: px(290);
      height: px(64);

      img {
        top: px(-34);
        left: px(-36);
        height: px(136);
      }
    }
  }
}
