// Dialog

.dialog {
  color: $white;
  width: px(720);
  height: px(71);
  font-size: px(20);
  line-height: 1.3em;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: px(5) px(15);
  text-shadow: 0 0 px(7) $colorBlue;
  background: url('#{$cdn}/img/bg-dialog.png') 0 0/100% 100% no-repeat;

  .t-cyan {
    color: $colorCyan;
  }

  .t-purple {
    color: $colorPurple;
  }

  &__close {
    display: inline-block;
    position: absolute;
    top: px(-15);
    right: px(-8);

    img {
      width: px(30);
    }
  }
}
