// Gifts

.modal-content {
  .gifts {
    padding-top: px(20);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: px(-25) auto;
    overflow: hidden;
    width: 92%;

    &__item {
      width: (100% / 5);
      text-align: center;
      padding: px(25);
    }
  }
}
