// Sweetalert2

.swal2-container {
  z-index: 1070 !important;
}

.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba($black, .85) !important;
}

.swal2-popup {
  border-radius: 0 !important;
  width: px(1488) !important;
  height: px(885) !important;
  padding: px(200) px(100) px(52) !important;
  background: url('#{$cdn}/img/bg-popup.png') 0 0/100% 100% no-repeat !important;

  .swal2-title {
    width: px(863);
    height: px(158);
    font-size: px(95);
    font-family: $font-family-cintra;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
    padding: px(10) 0 0;
    position: absolute;
    top: 0;
    left: calc(50% - #{px(863/2)});
    display: flex !important;
    align-items: center;
    justify-content: center;
    color: $white !important;
    white-space: nowrap;
  }

  .swal2-close {
    width: auto;
    height: auto;
    color: transparent;
    outline: none;
    top: px(2);
    right: px(-66);

    img {
      width: px(49);
    }
  }

  .popup-alert {
    &__content {
      color: $white;
    }
  }

  &.popup-error {
    .popup-alert {
      &__message {
        font-size: px(60);
      }
    }
  }

  &.popup-success {
    .popup-alert {
      &__message {
        font-size: px(45.49);
        line-height: 1.4em;
        font-weight: normal;
        font-style: italic;
        margin: px(35) 0 0;
      }

      &__gift {
        &--image {
          display: inline-block;
          margin-top: px(10);
          position: relative;

          img {
            max-width: 100%;
            height: px(300);
          }

          span {
            position: absolute;
            bottom: px(5);
            right: px(5);
            font-size: px(50);
            font-family: $font-family-roboto;
            font-weight: 700;
            color: yellow;
            text-shadow: px(1) px(1) px(4) $black;
          }
        }

        &--message {
          font-size: px(40);
          margin-top: px(30);

          span {
            color: $colorPink3;
          }
        }

        &--name {
          font-size: px(50);
          font-weight: 700;
          margin-top: px(20);
          color: $colorCyan2;
          text-transform: uppercase;
        }
      }
    }
  }

  &.popup-confirm, &.popup-confirm-jackpot {
    .swal2-actions {
      margin: px(60) 0 0 !important;

      button {
        border: 0 !important;
        margin: 0 !important;
        padding: 0 !important;
        color: $white !important;
        border-radius: 0 !important;
        font-weight: normal !important;
        box-shadow: none !important;
        text-transform: uppercase !important;
        font-size: px(50) !important;

        &.swal2-cancel {
          width: px(359) !important;
          height: px(100) !important;
          background: url('#{$cdn}/img/btn-cyan.png') 0 0/100% 100% no-repeat !important;
        }

        &.swal2-confirm {
          width: px(365) !important;
          height: px(108) !important;
          margin-left: px(100) !important;
          background: url('#{$cdn}/img/btn-pink.png') 0 0/100% 100% no-repeat !important;
        }
      }
    }
  }

  &.popup-confirm {
    .swal2-actions {
      margin: px(40) 0 0 !important;
    }
    .popup-alert {
      &__content {
        .custom-control {
          margin-top: px(40);
          text-align: center;

          .custom-control-label {
            font-size: px(36);
            font-weight: normal;
            font-family: $font-family-roboto;
            text-shadow: 0 0 px(10) $colorPink2;

            &:before, &:after {
              width: px(28);
              height: px(28);
              left: px(-41);
              top: px(6);
              border-radius: 0;
            }

            &:before {
              border: $colorCyan2 solid 1px;
              background-color: transparent;
            }
          }

          .custom-control-input:checked ~ .custom-control-label::before {
            background-color: $colorCyan2;
          }
        }
      }

      &__message {
        font-size: px(60);
        line-height: normal;
        font-weight: normal;
        margin: px(30) 0 0;
        text-shadow: 0 0 px(10) $colorPink2;

        span {
          display: inline-block;
          font-size: px(161);
          color: $colorCyan4;
          text-shadow: none;

          img {
            height: px(83);
            margin: px(40) px(10) 0 0;
          }

          strong {
            display: inline-block;
            @include gradientX(#00fff0, #d72df5);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
      }
    }
  }

  &.popup-confirm-jackpot {
    .swal2-actions {
      margin: px(45) 0 0 !important;

      button {
        &.swal2-cancel {
          margin-left: px(100) !important;
        }

        &.swal2-confirm {
          margin-left: 0 !important;
        }
      }
    }

    .popup-alert {
      &__title {
        width: px(863);
        height: px(158);
        font-size: px(95);
        font-family: $font-family-cintra;
        text-align: center;
        text-transform: uppercase;
        margin: 0;
        padding: px(10) 0 0;
        position: absolute;
        top: 0;
        left: calc(50% - #{px(863/2)});
        display: flex !important;
        align-items: center;
        justify-content: center;
        color: $white !important;
        white-space: nowrap;
      }

      &__jackpots {
        display: flex;
        justify-content: center;
      }

      &__jackpot {
        margin: 0 px(90);

        &--image {
          height: px(365);
        }

        &--name {
          font-size: px(25);
          margin: px(10) 0 0;
        }
      }
    }
  }
}
