// Balance

.balance {
  $self: '.balance';

  text-align: center;
  position: relative;
  width: px(1776);
  margin: 0 auto;

  // Content
  &__content {
    position: absolute;
    bottom: calc(100% + #{px(145)});
    min-height: px(322);
    left: 0;
    right: 0;
    margin: 0 auto;

    .dialog {
      margin: px(28) auto 0;
    }
  }

  &__score {
    display: flex;
    justify-content: center;
  }

  &__teams {
    color: $white;
    font-size: px(23.89);
    margin-top: px(10);

    &--left {
      color: $colorCyan;
    }

    &--right {
      color: $colorPurple;
    }
  }

  &__stacks {
    position: absolute;

    &--left {
      top: px(49);
      left: px(373);
    }

    &--right {
      top: px(53);
      right: px(378);
    }
  }

  &__coupon {
    position: absolute;
    top: px(21);
    color: inherit !important;

    &--left {
      left: 0;
    }

    &--right {
      right: 0;
    }
  }

  // Left Right Cards
  &__left, &__right {
    position: absolute;
    bottom: calc(100% + #{px(132)});
    z-index: 2;
    transition: all 600ms ease;
  }

  &__left {
    left: px(50);
  }

  &__right {
    right: px(50);
  }

  &__main {
    position: relative;

    &--head {
      position: absolute;
      top: px(-140);
      left: 0;
      width: 100%;
      height: px(227);
      z-index: 1;
      transition: all 600ms ease;

      &:before {
        content: '';
        width: px(1842);
        height: px(283);
        position: absolute;
        top: calc(50% - #{px(283/2)});
        left: calc(50% - #{px(1842/2)});
        user-select: none;
        pointer-events: none;
        background: url('#{$cdn}/img/icon-balance-head.png') 0 0/100% 100% no-repeat;
      }
    }

    &--foot {
      width: px(266);
    }
  }

  // Dialog
  &__dialog {
    position: absolute;
    z-index: 100;
    bottom: px(26);

    &--left {
      right: calc(50% + #{px(170)});
    }

    &--right {
      left: calc(50% + #{px(170)});
    }
  }

  // Animation
  &[data-side="left"] {
    #{$self}__main--head {
      transform: rotate(-7deg);
    }

    #{$self}__left {
      transform: translateY(px(80));
    }

    #{$self}__right {
      transform: translateY(px(-100));
    }
  }

  &[data-side="right"] {
    #{$self}__main--head {
      transform: rotate(7deg);
    }

    #{$self}__left {
      transform: translateY(px(-100));
    }

    #{$self}__right {
      transform: translateY(px(80));
    }
  }
}
