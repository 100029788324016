// Fonts

@font-face {
  font-family: 'SVN-Cintra';
  src: url('#{$cdn}/fonts/SVN-Cintra.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Airacobra';
  src: url('#{$cdn}/fonts/Airacobra.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Digital Desolation';
  src: url('#{$cdn}/fonts/Digital-Desolation.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'UTM Bebas';
  src: url('#{$cdn}/fonts/UTMBebas.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
