// Base

html {
  font-size: 6px;

  @include media-breakpoint-up(md) {
    font-size: 8px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 10px;
  }
}

html, body {
  overflow: hidden;
}

a {
  text-decoration: none !important;
}

* {
  outline: none !important;
}

.bg-image {
  background-size: cover;
  background-color: $gray-300;
  background-repeat: no-repeat;
  background-position: center center;
}

#root {
  background: url('#{$cdn}/img/background.jpg') 0 0/100% 100% no-repeat;
}

#main {
  position: relative;
  min-height: 100vh;

  &>.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
