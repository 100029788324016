// Card

.card {
  $self: '.card';

  display: block;
  position: relative;
  width: px(312);
  height: px(467);
  animation: twinkle 4000ms infinite;

  &:before {
    content: '';
    display: block;
    width: px(407);
    height: px(536);
    user-select: none;
    pointer-events: none;
    position: absolute;
    z-index: 1;
    top: calc(50% - #{px(536/2)});
    left: calc(50% - #{px(407/2)});
  }

  &.loading {
    pointer-events: none !important;
  }

  &__gifts {
    position: absolute;
    z-index: 10;
    top: 0;

    .gift-item ~ .gift-item {
      margin-top: px(7);
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: $black;
  }

  &[data-side="left"] {
    &:before {
      background: url('#{$cdn}/img/bg-card-left.png') 0 0/100% 100% no-repeat;
    }

    #{$self}__gifts {
      right: calc(100% + #{px(22)});
    }
  }

  &[data-side="right"] {
    &:before {
      background: url('#{$cdn}/img/bg-card-right.png') 0 0/100% 100% no-repeat;
    }

    #{$self}__gifts {
      left: calc(100% + #{px(22)});
    }
  }
}
