// Stacks

.stacks {
  $self: '.stacks';

  position: relative;
  display: flex;
  align-items: center;

  &[data-side="left"] {
    width: px(369);
    height: px(23);
    justify-content: flex-start;

    img {
      height: px(26);
    }
  }

  &[data-side="right"] {
    width: px(362);
    height: px(21);
    justify-content: flex-end;

    img {
      height: px(22);
    }
  }
}
